type dataTypes = {
    features: string[]
}

export const Features = ({ features }: dataTypes) => {
    return (
        <div className="features">
            {features !== undefined && 
                <ul>
                    {features.map((e, i) => {
                        return <li key={i}>{e}</li>
                    })}
                </ul>
            }
        </div>
    );
};
