import { useEffect, useState } from "react";

import "./Contact.css";

import { ReactComponent as Linkedin } from "assets/logos/linkedin.svg";
import { ReactComponent as Facebook } from "assets/logos/facebook.svg";
import { ReactComponent as Gmail } from "assets/logos/gmail.svg";

export const Contact = () => {

    const [copied, setCopied] = useState(false);
    const [times, setTimes] = useState(0);

    const timesArr = [
        { id: 1, text:"Copied!" },
        { id: 2, text: "Double Copy!" },
        { id: 3, text: "Triple Copy!" },
        { id: 4, text: "Dominating!" },
        { id: 5, text: "Rampage!" },
    ];

    const handleCopy = (e:any) => {
        if(!copied) {
            setCopied(true);
            setTimes(p => p + 1);
            navigator.clipboard.writeText(e.innerText);
    
            setTimeout(() => {
                setCopied(false);
            }, 1500);
        }
    }

    const copyText = timesArr.filter(e => e.id === times);

    useEffect(() => {
        if(times > 5) {
            setTimes(0);
        }
    }, [times]);


    return (
        <div className="contactWrapper" id="contactMe">
            <h3>Contact</h3>
            <div className="contactParent">
                <div>
                    <p>Contact me on: </p> 
                    <a href="https://www.linkedin.com/in/giorgi-sanodze-a6002821a/" target="_blank" rel="noreferrer"><Linkedin /></a>
                    <a href="https://www.facebook.com/sanodzeg/" target="_blank" rel="noreferrer"><Facebook /></a>
                    <a href="mailto:sanodzegg@gmail.com"><Gmail /></a>
                </div>
                <p className="email" onClick={(e) => handleCopy(e.target)}>sanodzegg@gmail.com</p>
                {copied && <span className={`${times === 4 ? "mad" : times === 5 ? "max" : ""}`}>{copyText[0]?.text}</span>}
            </div>
        </div>
    );
};
