import { ReactComponent as ReactLogo } from "assets/logos/reactLogo.svg";
import { ReactComponent as VueLogo } from "assets/logos/vueLogo.svg";
import { ReactComponent as JSLogo } from "assets/logos/jsLogo.svg";
import { useEffect, useRef, useState } from "react";

import "./FMDisplay.css";

export const FMDisplay = ({ setDisplay }:any) => {
    const [mob, setMob] = useState(false);

    const reactRef = useRef(null);
    const vueRef = useRef(null);
    const JSRef = useRef(null);

    const logos = [
        <ReactLogo className="FMReact" ref={reactRef} />, 
        <VueLogo className="FMVue" ref={vueRef} />, 
        <JSLogo className="FMJs" ref={JSRef} />
    ];

    const handleLogoClick = (e:EventTarget, index:number) => {
        const node = e as HTMLDivElement;
        
        const others = node.parentNode?.childNodes;
        others?.forEach(e => {
            (e as HTMLDivElement).classList.remove("active");
        });

        node.classList.add("active");

        if(index === 2 && others) {
            (others[0] as HTMLDivElement).style.left = "81%";
            setDisplay(2);
        } else if(index === 1 && others) {
            (others[0] as HTMLDivElement).style.left = "15%";
            setDisplay(1);
        } else setDisplay(0);
    }

    const handleResize = () => {
        if(window.innerWidth > 535) {
          setMob(false);
        } else setMob(true);
      }
    
    useEffect(() => {
      handleResize();
    }, [])

    useEffect(() => {
        setDisplay(0);
    }, [mob])

    window.addEventListener("resize", handleResize);

    return (
        <div className="FMDisplayWrapper">
            {logos.map((e, i) => {
                return !mob ? <div className={i === 0 ? "svgWrapper active" : "svgWrapper"} key={i} onClick={(e) => handleLogoClick(e.target, i)}>{e}</div>
                : <div className={i === 0 ? "svgBox active" : "svgBox"} key={i} onClick={(e => handleLogoClick(e.target, i))}>{e}</div>
            })}
        </div>
    );
};
