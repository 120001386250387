import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { Home } from './views/Home/Home';
import { ProjectDetailed } from 'views/ProjectDetailed/ProjectDetailed';
import { ErrPage } from './views/404/ErrPage';
import Scroller from 'Scroller';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Router>
      <Scroller />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/project/:projectName" element={<ProjectDetailed />} />
        <Route path="*" element={<ErrPage />} />
      </Routes>
    </Router>
  </React.StrictMode>
);