import "./MySkills.css";

export const MySkills = () => {
  return (
    <div className="skillsWrapper" id="mySkills">
        <h3>Professional skills</h3>
        <div className="skillWrapper">
            <div className="skillHolder">
                <p>HTML, CSS (SASS/LESS)</p>
                <p>Intermediate</p>
            </div>
            <div className="skillHolder">
                <p>Responsive UI Design</p>
                <p>Intermediate</p>
            </div>
            <div className="skillHolder">
                <p>JavaScript, jQuery</p>
                <p>Intermediate</p>
            </div>
            <div className="skillHolder">
                <p>ReactJS / NextJS</p>
                <p>Intermediate</p>
            </div>
            <div className="skillHolder">
                <p>TypeScript</p>
                <p>Competent</p>
            </div>
            <div className="skillHolder">
                <p>Adobe / Figma</p>
                <p>Competent</p>
            </div>
        </div>
        <h3>Languages</h3>
        <div className="languageWrapper">
            <div className="languageHolder">
                <p>Georgian</p>
                <p>Native</p>
            </div>
            <div className="languageHolder">
                <p>English</p>
                <p>Proficient</p>
            </div>
        </div>
    </div>
  );
};
