import "./About.css";

export const About = () => {
  return (
    <div className="mainAbout">
        <h2>Hello, I'm <span>Giorgi</span></h2>
        <p>I blend design with programming to deliver an immersive user experience.</p>
    </div>
  );
};
