import { useOnOutsideClick } from "hooks/useOutside";
import { useRef } from 'react';

type propTypes = {
    src: string,
    setDisplay: any
}

export const DisplayImg = ({ src, setDisplay }:propTypes) => {

    const imgRef = useRef(null);

    useOnOutsideClick(imgRef, () => setDisplay(null));

    return (
        <div className="displayImgWrapper">
            <div ref={imgRef} className="imgWrapper">
                <img src={src} alt="displayed" />
            </div>
        </div>
    );
};
