import { useState } from 'react';

import "./ProjectDetailed.css";

import detailed from "detailed.json";
import { useNavigate, useParams } from "react-router-dom";

import { ErrPage } from 'views/404/ErrPage';
import { Features } from './DetailedComps/Features';
import { BuiltWith } from './DetailedComps/BuiltWith';
import { Pictures } from './DetailedComps/Pictures';
import { DisplayImg } from './DetailedComps/DisplayImg';
import { HowToUse } from './DetailedComps/HowToUse';
import { Footer } from 'views/Home/components/Footer/Footer';

import { ReactComponent as GoBack } from "assets/icons/back.svg";

type dataType = {
    BookingApp: object
}

type projectTypes = {
    projectName: string,
    description: string,
    features: string[],
    builtWith: object[],
    pictures: string[],
    gitLinks: object[],
    steps: string[],
    useSteps: string[]
}

export const ProjectDetailed = () => {
    const [projectData, setProjectData] = useState<Partial<projectTypes>>({});
    const [displayImg, setDisplayImg] = useState(null);

    const navigate = useNavigate();

    const params = useParams();

    const projectName = params.projectName;

    if(!projectData) return <ErrPage />

    const isData = Object.values(projectData).length > 0;

    if(projectName && projectName && !isData) {
        const data:dataType = detailed;
        const currentProjectObject = data[projectName as keyof dataType];
        
        setProjectData(currentProjectObject);
    }

    if(isData && projectData) {
        return (
            <>
                <GoBack onClick={() => navigate(-1)} className="backArrow" />
                <div className="detailedWrapper">
                    <h1>{projectData.projectName}</h1>
                    <p>{projectData.description}</p>

                    <h3>Features</h3>
                    { projectData.features !== undefined && <Features features={projectData.features} /> }
                    { projectData.builtWith !== undefined && <BuiltWith languages={projectData.builtWith} /> }
                    { projectData.pictures !== undefined && <Pictures pictures={projectData.pictures} setDisplay={setDisplayImg} /> }

                    { displayImg && <DisplayImg src={displayImg} setDisplay={setDisplayImg} /> }
                    { projectData.gitLinks !== undefined && <HowToUse links={projectData.gitLinks} steps={projectData.steps} useSteps={projectData.useSteps} /> }
                </div>
                <Footer />
            </>
        );
    } else return <ErrPage />;
};
