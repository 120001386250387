import { useState } from "react";

import { Navbar } from "../../snackbars/navbar/Navbar";

import { About } from "./components/about/About";
import { AboutMe } from "views/Home/components/AboutMe/AboutMe";

import { FMDisplay } from './components/frameworks/FMDisplay';
import { ReactProjects } from "./components/react-projects/ReactProjects";
import { VueProjects } from "./components/vue-projects/VueProjects";

import "./Home.css";
import { JSProjects } from './components/js-projects/JSProjects';
import { MySkills } from "./components/MySkills/MySkills";
import { Contact } from "./components/contact/Contact";
import { Footer } from "./components/Footer/Footer";
import DLResume from "snackbars/navbar/Comps/PortfolioDL/DLResume";

export const Home = () => {

  const [displayProject, setDisplayProject] = useState(0);

  return (
    <main>
      <Navbar />
      <div className="mainWrapper" id="myWork">
        <About />
        <FMDisplay setDisplay={setDisplayProject} />
      </div>
      {displayProject === 0 ? <ReactProjects /> : displayProject === 1 ? <VueProjects /> : <JSProjects />}
      <hr className="divisor" />
      <AboutMe />
      <MySkills />
      <Contact />
      <DLResume />
      <Footer />
    </main>
  );
};
