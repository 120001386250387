import "./Footer.css";

export const Footer = () => {
    
    const phone = "+995598164488";
    const mail = "sanodzegg@gmail.com";

    return (
        <div className="footerWrapper">
            <hr className="footerHR" />
            <div className="footerDiv">
                <div className="location">
                    <p>Located</p>
                    <a href="https://shorturl.at/ajrz2" target="_blank" rel="noreferrer">Tbilisi, Georgia</a>
                </div>
                <div className="contact">
                    <a href={`mailto:${mail}`}>M sanodzegg@gmail.com</a>
                    <a href={`tel:${phone}`}>T +995 598 16 44 88</a>
                </div>
            </div>
        </div>
    );
};
