type langTypes = {
    languages: object[]
}

type objTypes = {
    link?: string,
    logo?: string
}


export const BuiltWith = ({ languages }:langTypes) => {
    return (
        <>
            <h3>Project built with</h3>
            <ul className="builtWithList">
                {languages.map((obj:objTypes, i:number) => {
                    return <li key={i}><a href={obj.link} target="_blank" rel="noreferrer"><img src={obj.logo} /></a></li>
                })}
            </ul>
        </>
    );
};