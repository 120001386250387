import "./AboutMe.css";

export const AboutMe = () => {
  return (
    <div className="aboutWrapper" id="aboutMe">
      <div className="aboutRow">
        <h3>About me</h3>
        <p>I'm a Front-End Developer located in Georgia. I'm passionate about creating intuitive and appealing user experiences.
          It's been a year that I've been in the field and programming itself, throughout the period I've worked on multiple projects
          to gain and grow my knowledge in the Front-End field.
        </p>
        <h3>Education</h3>
        <div className="edWrapper">
          <h4>Information Technologies</h4>
          <span>2019 - 2023</span>
          <p><a href="https://btu.edu.ge/" target="_blank" rel="noreferrer">Business and Technologies University - Tbilisi, Georgia</a></p>
        </div>
        <div className="edWrapper">
          <h4>VueJS Trainee</h4>
          <span>2021 - 2022</span>
          <p><a href="https://ict.gov.ge/" target="_blank" rel="noreferrer">GITA - Georgia</a></p>
          <span className="cp">Certified</span>
        </div>
        <h3>Experience</h3>
        <div className="expWrapper">
          <h4>UI/UX Designer</h4>
          <span>Sep 2020 - Feb 2021</span>
          <p><a href="https://www.facebook.com/arttteosoftware" target="_blank" rel="noreferrer">Arttteo - Software Development</a></p>
          <p>Responsible for the implementation of UX/UI design for various platforms.</p>
        </div>
        <div className="expWrapper">
          <h4>Front End Intern</h4>
          <span>Dec 2021 - Mar 2022</span>
          <p><a href="https://www.facebook.com/UniLabEdu" target="_blank" rel="noreferrer">UniLab - Georgia</a></p>
          <p>Expand features, refine code, and improve processes, producing smoother operations and enhancing user engagement.</p>
          <p className="p2">Involved in producing websites used by the University itself. Designed dynamic and browser compatible pages using HTML5, CSS3(SCSS), jQuery, and Javascript.</p>
          <span className="cp">Certified</span>
        </div>
      </div>
    </div>
  );
};
