import NavbarPC from "snackbars/navbar/Nav/NavbarPC"

const ScrollTo = ({ emitClose }:{ emitClose:any }) => {
  return (
    <div className='scrollTo'>
        <h3>Scroll to: </h3>
        <NavbarPC emitCloseFunc={emitClose} />
    </div>
  )
}

export default ScrollTo