type wrapperProps = {
    src: string,
    name: string,
    description: string
}

export const WrapperDiv = ({ src, name, description }:wrapperProps) => {
    return (
        <>
            <div className="imgWrapper">
                <img src={src} alt="react project visual" />
            </div>
            <h3>{name}</h3>
            <p>{description}</p>
        </>
    )
}