import { Link } from "react-router-dom";
import { WrapperDiv } from "../Project/Project";

export const LinkComp = (e:any) => {
    const data = e.data;
        
    if(data.link) {
        return (
            <a href={data.link} target="_blank" rel="noreferrer">
                <WrapperDiv src={data.src} name={data.name} description={data.description} />
            </a>
        );
    } else {
        return (
            <Link to={`/project/${data.name.replace(/\s/g, "")}`}>
                <WrapperDiv src={data.src} name={data.name} description={data.description} />
            </Link>
        );
    }
};
