
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";

const DLResume = () => {
  return (
    <a className='dlResume' href="https://drive.google.com/file/d/1b8rmJoo6hTDxxvsX2Jk1-vY8GcCn9F1s/view" target="_blank" rel="noreferrer">
        <DownloadIcon />
        <p>Get my resume</p>
    </a>
  )
}

export default DLResume