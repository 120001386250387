export const HowToUse = ({ links, steps, useSteps }:any) => {

    return (
        <>
            <h3>How to use?</h3>
            <div className="htuWrapper">
                <a href={links.repo} target="_blank" rel="noreferrer">Click me if you want to see this project on Github.</a><br />
                <a href={links.zip} target="_blank" rel="noreferrer">Click me if you want to download the ZIP file of the project.</a>

                <div className="htuHTR">
                    <h3>Steps to run the project:</h3>
                    <ul>
                        {steps.map((e:string, i:number) => {
                            return <li key={i}>{e.replace(`${e.match(/`(.*?)`/g)}`, "")} {e.match(/`(.*?)`/g) && <span>{`${e.match(/`(.*?)`/g)}`?.replace(/`/g, "")}</span>}</li>
                        })}
                    </ul>
                    <h3>Steps to use the application:</h3>
                    <ul>
                        {useSteps.map((e:string, i:number) => {
                            return <li key={i}>{e}</li>
                        })}
                    </ul>
                </div>
            </div>
        </>
    );
};
