

type pictureTypes = {
    pictures: string[],
    setDisplay: any
}

export const Pictures = ({ pictures, setDisplay }:pictureTypes) => {

  const handleImgClick = (e:HTMLImageElement) => {
    setDisplay(e.src);
  }
  
  return (
    <>
      <h3>Pictures</h3>
      <div className="picturesWrapper">
          <div className="mainPicWrapper">
            <img src={pictures[0]} alt="project main" onClick={(e) => handleImgClick(e.target as HTMLImageElement)} />
          </div>
          <div className="secondaryPics">
            {pictures.map((e, i) => {
              return i !== 0 ? <img key={i} src={e} alt="project secondary" onClick={(e) => handleImgClick(e.target as HTMLImageElement)} /> : null;
            })}
          </div>
      </div>
    </>
  );
};
