import { useEffect, useState } from "react";
import data from "projects.json";
import "./JSProjects.css";

import { useSpring, animated } from 'react-spring'
import { LinkComp } from "../Link/Link";

export const JSProjects = () => {

    const [divHeight, setDivHeight] = useState(0);

    const wrapperStyle = useSpring({
        from: { height: "0px" },
        to: { height: `${divHeight}px` },
    });

    const handleResize = () => {
        const vw = window.innerWidth;
        if(vw <= 1075 && vw > 625) {
            setDivHeight(1500);
        } else if(vw <= 625) {
            const reducedBy = 625 - vw;
            setDivHeight(1500 + reducedBy);
        } else setDivHeight(770);
    }
    
    useEffect(() => {
        handleResize();
    }, []);

    window.addEventListener("resize", handleResize);

    return (
        <animated.div style={wrapperStyle} className="jsWrapper">
            {data?.vanilla.map((e, i:number) => {
                return (
                    <div className="jsApp" key={i}>
                        <LinkComp data={e} />
                    </div>
                )
            })}
        </animated.div>
    );
};