import { useEffect, useState } from "react";
import data from "projects.json";
import "./ReactProjects.css";

import { useSpring, animated } from 'react-spring'
import { LinkComp } from "../Link/Link";

export const ReactProjects = () => {

    const [divHeight, setDivHeight] = useState(0);

    const wrapperStyle = useSpring({
        from: { height: "0px" },
        to: { height: `${divHeight}px` },
    });

    
    const handleResize = () => {
        const vw = window.innerWidth;
        if(vw <= 1500 && vw > 1075) {
            setDivHeight(1210);
        } else if (vw <= 1075 && vw > 405) {
            setDivHeight(1835);
        } else if (vw <= 405) {
            const reducedBy = 405 - vw;
            setDivHeight(2045 + reducedBy);
        } else setDivHeight(765);
    }

    useEffect(() => {
        handleResize();
    }, []);

    window.addEventListener("resize", handleResize);

    return (
        <animated.div style={wrapperStyle} className="reactWrapper">
            {data?.react.map((e, i:number) => {
                return (
                    <div className="reactApp" key={i}>
                        <LinkComp data={e}/>
                    </div>
                )
            })}
        </animated.div>
  );
};