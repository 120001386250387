import { useNavigate } from "react-router-dom";
import "./ErrPage.css";

export const ErrPage = () => {

    const navigate = useNavigate();

    return (
        <div className="errPageWrapper">
            <div className="errPageWrapper">
                <h1>page not found</h1>
                <button onClick={() => navigate("/")}>go home</button>
            </div>
        </div>
    );
};
