const NavbarPC = ({ emitCloseFunc }:{ emitCloseFunc?: any }) => {
  return (
    <>
        <ul>
            <li><a href="#myWork" onClick={() => emitCloseFunc && emitCloseFunc()}>My Work</a></li>
            <li><a href="#aboutMe" onClick={() => emitCloseFunc && emitCloseFunc()}>About</a></li>
            <li><a href="#mySkills" onClick={() => emitCloseFunc && emitCloseFunc()}>My Skills</a></li>
            <li><a href="#contactMe" onClick={() => emitCloseFunc && emitCloseFunc()}>Contact</a></li>
        </ul>
    </>
  )
}

export default NavbarPC