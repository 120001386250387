import { useEffect, useState } from "react";
import NavbarMob from "./Nav/NavbarMob";
import NavbarPC from "./Nav/NavbarPC";
import "./Navbar.css";

export const Navbar = () => {
  const [mob, setMob] = useState(false);
  const [shrink, setShrink] = useState(false);

  const handleResize = () => {
    if(window.innerWidth > 530) {
      setMob(false);
    } else setMob(true);
  }

  useEffect(() => {
    handleResize();
    handleScroll();
  }, [])

  const handleScroll = () => {
    if(mob && window.scrollY > 150) {
      setShrink(true);
    } else setShrink(false);
  }

  window.addEventListener("resize", handleResize);
  window.addEventListener("scroll", handleScroll);

  return (
    <nav className={`navbarWrapper${mob && !shrink ? " mobile" : shrink ? " mobile shrinked" : ""}`}>
        {mob ? <NavbarMob /> : <NavbarPC />}
    </nav>
  );
};
