import { ReactComponent as Logo } from "assets/icons/burger.svg"
import { useOnOutsideClick } from "hooks/useOutside";
import { useState, useRef, useEffect } from "react";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import ScrollTo from "../Comps/ScrollTo/ScrollTo";
import DLResume from "../Comps/PortfolioDL/DLResume";
import { Contact } from "views/Home/components/contact/Contact";

const BurgerNav = ({ reference, displayed, closeBurger }:{ reference: any, displayed: boolean, closeBurger:any }) => {
  const openMenu = () => {
    if(reference.current) {
      setTimeout(() => {
        reference.current.style.right = "0px";
      }, 10);
    }
  }

  useEffect(() => {
    if(displayed) {
      openMenu();
    }
  }, [displayed]);

  return (
    <div ref={reference} className={`burgerWrapper${displayed ? " active" : ""}`}>
      <CloseIcon onClick={() => closeBurger()} />
      <ScrollTo emitClose={closeBurger} />
      <Contact />
      <DLResume />
    </div>
  );
}

const NavbarMob = () => {
  const [displayBurger, setDisplayBurger] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const closeMenu = ():void => {
    if(menuRef.current) {
      menuRef.current.style.right = "-250px";
      setTimeout(() => {
        setDisplayBurger(false)
      }, 500);
    }
  }

  useOnOutsideClick(menuRef, () => closeMenu());

  return (
    <>
      <h1 onClick={() => window.scrollTo(0, 0)}>GS</h1>
      <Logo className="burger" onClick={() => setDisplayBurger(true)} />
      {displayBurger && <BurgerNav reference={menuRef} displayed={displayBurger} closeBurger={closeMenu} />}
    </>
  )
}

export default NavbarMob